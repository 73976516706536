import { Platform } from 'react-native';

let environment = 'production';
let apiUrl = [];

if(Platform.OS == 'web'){
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        // 当前页面在 localhost 上运行
        environment = 'local';
      } 
}


if (environment === 'production') {
    apiUrl = [
        'https://id67.com',
        'https://www.id67.com',
        
    ];
}

if (environment === 'development') {
    apiUrl = [
        // 'https://www.jogoeletronicos.com',
        'https://zoushi.thai2565.com',
        'https://test.thai2565.com',
    ];
}

if (environment === 'local') {

    if(Platform.OS == 'web'){
        apiUrl = [
            'http://local.api.com/proxy.php', // 本地web会走代理
        ];
    }else{
        apiUrl = [
            'https://id67.com',
        ];
    }
    
}

export default {
    apiUrl, // API地址
    env:environment,
    facebookPixelID:'12345678',
    googleAnalyticsID:'UA-12345678-9',
    tiktokPixelID:'12345678',
    logoStyle:{
        width: 150,
        height: 30,
        marginTop:10,
        marginBottom: 0
    },
    channelPayBankHide:false
};
