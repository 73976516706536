import React, { useRef, useStatem, useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, ScrollView } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { Feather,AntDesign  } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style';
import ContractComponent from './contractComponent';
import Option from './option';
import { virtual, virtual2 } from '../components/util'
import { getWebsiteInfo } from '../api/apis';

const App = (props) => {

    const navigation = useNavigation();
    const { t } = useTranslation();

    const contractComponentRef = useRef(null);
 
    const [websiteInfoData, setWebsiteInfoData] = useState(false);

    const [tab, setTab] = useState(1);
    const [currency, setCurrency] = useState('BTC');
    const [chart, setChart] = useState(true);
    const [headerRightButton, setHeaderRightButton] = useState('none');

    const handleHeaderRightClick = () => {
        if (headerRightButton == 'none') {
            setHeaderRightButton('block')
        } else {
            setHeaderRightButton('none')
        }
    };

    React.useLayoutEffect(() => {

        navigation.setOptions({
            headerLeft: () => (
                <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => handleHeaderRightClick()}>
                    <Feather name="list" size={24} color='#fff' />
                    <Text style={{ color: '#fff', marginLeft: 5, fontSize: 18 }}>{currency}/USDT</Text>
                </TouchableOpacity>
            ),
            headerRight: () => (
                <View style={{flexDirection:'row',alignItems:'center'}}>
                    {tab == 2 && <TouchableOpacity onPress={() => { navigation.navigate('BetRecord') }}>
                        <AntDesign name="filetext1" size={20} color="#fff" style={{marginRight:5}} />
                    </TouchableOpacity>}
                    {chart && tab == 1 && <TouchableOpacity onPress={() => { navigation.navigate('Chart', { currency: currency }) }}>
                        <Image style={{ width: 30, height: 30,marginRight: 5 }} source={require('../../assets/imgs/contract/tab.png')} />
                    </TouchableOpacity>}
                </View>
            ),
        });
    }, [navigation, headerRightButton,currency,tab]);


    useEffect(() => {
        
        init()

        if (contractComponentRef.current) {
            contractComponentRef.current.goToExchangeScreen(currency);
        }
    }, [currency]);

    useFocusEffect(
        React.useCallback(() => {
            if(props?.route?.params?.currency && props?.route?.params?.currenc != currency){
                handleCurrency(props?.route?.params?.currency)
            }
        }, [props,currency])
    );

    const init = async () => {
        const response = await getWebsiteInfo();
        if (response.status == '1') {
            setWebsiteInfoData(response.Data)
        }
    }

    const handleCurrency = (val) => {

        if(props?.route?.params?.currency){
            props.route.params.currency = false
        }

        setCurrency(val)
        setHeaderRightButton('none')

        if (Object.values(virtual2()).includes(val)) {
            setChart(false)
        } else {
            setChart(true)
        }

    }

    // goToExchangeScreen()

    // 
    return (
        <View style={styles.container} >

            {/* <View style={{ display: headerRightButton, backgroundColor: 'rgba(18, 20, 32, 1)', borderRadius: 3, borderWidth: 1, borderColor: 'gray', width: 305, position: 'absolute', top: 0, left: 5, zIndex: 999 }}> */}
                {/* <View> */}

                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        showsHorizontalScrollIndicator={false}
                        style={{ flexDirection: 'row', flexWrap: 'wrap',display: headerRightButton, top: 0, left: 5, zIndex: 999 }}
                    >
                        {Object.entries(virtual()).map(([symbol, name]) => 
                        // (websiteInfoData && (websiteInfoData.hasOwnProperty(`COIN_${symbol}`) && websiteInfoData[`COIN_${symbol}`] == 1) || !websiteInfoData.hasOwnProperty(`COIN_${symbol}`)) &&
                        (
                            <View key={name}>
                                <TouchableOpacity onPress={() => handleCurrency(symbol)}>
                                    <View style={{ width: 100 }}>
                                        <Text style={{ textAlign: 'center', color: '#fff', marginVertical: 10 }}>{symbol}/USDT</Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        ))}
                        {Object.entries(virtual2()).map(([symbol, name]) => 
                        (websiteInfoData && (websiteInfoData.hasOwnProperty(`COIN_${symbol}`) && websiteInfoData[`COIN_${symbol}`] == 1) || !websiteInfoData.hasOwnProperty(`COIN_${symbol}`)) &&
                        (
                            <View key={name}>
                                {tab == 1 && <TouchableOpacity onPress={() => handleCurrency(symbol)}>
                                    <View style={{ width: 100 }}>
                                        <Text style={{ textAlign: 'center', color: '#fff', marginVertical: 10 }}>{symbol}/USDT</Text>
                                    </View>
                                </TouchableOpacity>}
                            </View>
                        ))}
                    </ScrollView>
                 
                {/* </View> */}
            {/* </View> */}


            {headerRightButton == 'none' && <View style={{width:'100%',flexDirection: 'row', justifyContent: 'space-around', marginBottom: 10 }}>
                <TouchableOpacity style={[tab == 1 && styles.tabActive, { borderBottomColor: commonStyle.green, padding: 5, flex: 1 }]} onPress={() => { setTab(1) }}>
                    <Text style={{ color: '#fff', fontSize: 16, textAlign: 'center' }}>{t('perpetualContract')}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[tab == 2 && styles.tabActive, { borderBottomColor: commonStyle.green, padding: 5, flex: 1 }]} onPress={() => { setTab(2) }}>
                    <Text style={{ color: '#fff', fontSize: 16, textAlign: 'center' }}>{t('optionsTrading')}</Text>
                </TouchableOpacity>
            </View>}

            {tab == 1 && <ContractComponent ref={contractComponentRef} currency={currency} />}
            {tab == 2 && <Option ref={contractComponentRef} currency={currency} />}

        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center'
    },
    tabActive: {
        borderBottomWidth: 2,
    }
});

export default App;