import React from 'react';
import { StyleSheet, View, Text, ScrollView, Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { commonStyle } from '../components/style';

const ArticleDetail = (props) => {
  const { i18n, t } = useTranslation();
  const title_id = props.route.params.articleId
  let lang =  i18n.language.toUpperCase()
  if (lang != 'CN' && lang != 'EN') {
    lang = 'EN'
  }

  const navigation = useNavigation();

  let title = ''
  if (title_id == 1) {
    title = t('aboutUs');
  }
  if (title_id == 2) {
    title = t('userTerms');
  }
  if (title_id == 3) {
    title = t('privacyPolicy');
  }

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: title,
      headerStyle: {
        backgroundColor: commonStyle.black,
        borderBottomWidth: 0
      },
      headerTitleStyle: {
      },
    });
  }, [navigation]);


  const articleContent = {
    CN: {
      1: `
      Coinbase是一家美国加密货币交易所。该公司成立于2012年。截至2021年3月，Coinbase是美国交易量最大的加密货币交易所。2021年4月13日，Coinbase在那斯达克交易所上市，成为美国首家上市的加密货币公司   Coinbase Pro总部设立在美国，为用户提供安全的平台，方便用户进行各种数字资产投资。Coinbase Pro平台界面简洁易用，包括实时订单查询、图表工具、交易历史记录和简单的订单流程。      `,
      2: `
      感谢您选择Coinbase产品。 
      <br> Coinbase是一个专门供用户进行区块链相关服务（以下称“该服务”或“服务”）的平台。为了本协议表述之方便，公司和该网站在本协议中合称为“我们”或其他第一人称称呼。只要登陆该网站的自然人或其他主体均为本网站的用户，为了本协议表述之便利，合称为“您”或其他第二人称。为了本协议表述之便利，我们和您在本协议中合称为“双方”，我们或您单称为“一方”。本网站所有内容，为便利用户，可能提供多个语言版本，若有冲突或遗漏等情况，以中文内容为准。 
      <br> 重要提示： 
      <br> 我们在此特别提醒您： 
      <br> 1、数字资产本身不由任何金融机构或公司或本网站发行； 
      <br> 2、数字资产市场是全新的、未经确认的，而且可能不会增长； 
      <br> 3、数字资产投资存在极高的风险，非专业团队或机构请谨慎投资； 
      <br> 4、禁止使用本网站从事洗钱、走私、商业贿赂等一切非法交易活动，若发现此类事件，本站将采取各种可使用之手段，包括但不限于冻结账户，通知相关权力机关等，我们不承担由此产生的所有责任和相关费用，并保留向相关人士追究责任的权利。 
      <br> 5、禁止使用本网站进行恶意操纵市场、不正当交易等一切不道德交易活动，若发现此类事件，本网站将对所有恶意操纵价格、恶意影响交易系统等不道德的行为采取警告、限制交易、关停账户等预防性地保护措施，我们不承担由此产生的所有责任和相关费用，并保留向相关人士追究责任的权利。 
      <br> 6、理财挖矿的金额和利润只能在交易后提取。 
      <br> 7、当您取款时，网站将收取10%的佣金。本网站VIP5以下用户每月只能提款1次 ，VIP5及以上的用户每月可以提取3次。 
      <br> 8、每个用户存入的 USDT 需要全额交易一次，交易量完成后方可提现。
      <br> 9、账户信誉分评分为100分。如果用户分数低于100分，则为不良账户，提现将失败。若信誉评分不足可以通过充值的方式进行，即1000USDT=1%积分。
      <br> 一、总则 
      <br> 1.1 《用户协议》（以下称“本协议”或“本条款及条件”），由正文、《隐私政策》、《用户反洗钱条例》以及本网站已经发布的或将来可能发布的各类规则、声明、说明等构成。 
      <br> 1.2 您在使用本网站提供的各项服务之前，应仔细阅读本协议，如有不理解之处或其他必要，请咨询专业律师。如您不同意本协议或对其有任何疑问，请您立即停止使用本网站提供的服务或不再登陆本网站。您一旦登陆本网站、使用本网站的任何服务或任何其他类似行为即表示您已了解并完全同意本协议各项内容，包括本网站对本协议随时所做的任何修改。 
      <br> 1.3 您通过按照本网站的要求填写相关信息，并经过其他相关程序后成功注册可以成为本网站的用户，在进行注册过程中点击“同意”按钮即表示您以电子签署的形式与公司达成协议；或您在使用本网站过程中点击任何标有“同意”或类似意思的按钮的行为或以其他本网站允许的方式实际使用本网站提供的服务时，均表示您完全了解、同意且接受本协议项下的全部条款的约束，无您手写的书面签字就本协议对您的法律约束力无任何影响。 
      <br> 1.4 您成为本网站的用户后，您将获得一个用户帐号及相应的密码，该账号和密码由用户负责保管；用户应当对以其您帐号进行的所有活动和事件负法律责任。 
      <br> 1.5 只有注册成为本网站的用户才可使用本网站提供的数字资产交易平台进行交易及享受其他本网站规定的只有用户才可获得的服务。 
      <br> 1.6 通过注册和使用任何由本网站提供的服务和功能，您将被视为已阅读，理解并接受本协议所有条款及条件的约束： 
      <br> 1.6.1 您确认您已年满18周岁或根据不同的可适用的法律规定的具有可订立合同的法定年龄，您在本网站的注册、销售或购买、发布信息等接受本网站服务的行为应当符合对您有管辖权的主权国家或地区相关法律法规，并有充分的能力接受这些条款，并订立交易，使用本网站进行数字资产交易。 
      <br> 1.6.2 您保证交易中涉及到的属于您的数字资产均为合法取得并所有。 
      <br> 1.6.3 您同意您为您自身的交易或非交易行为承担全部责任和任何收益或亏损。 
      <br> 1.6.4 您确认注册时提供的信息是真实和准确的。 
      <br> 1.6.5 您同意遵守任何有关法律的规定，就税务目的而言，包括缴纳20%个人所得税以及报告任何交易利润。 
      <br> 1.6.6 您遵守所有适用法律要求，包括但不限于所有税法和法规、外汇管制要求和注册要求。 
      <br> 1.6.7 您同意在任何时候不得从事或参与损害本网站或公司利益的行为或活动，无论是否与本网站提供的服务有关。 
      <br> 1.6.8 本协议只是就您与我们之间达成的权利义务关系进行约束，而并不涉及本网站用户之间与其他网站和您之间因数字资产交易而产生的法律关系及法律纠纷。 
      <br> 二、协议修订 我们保留随时修订本协议、并以网站公示的方式进行公告、不再单独通知您的权利，变更后的协议会在本协议首页标注变更时间，一经在网站公布，立即自动生效。您应不时浏览及关注本协议的更新变更时间及更新内容，如您不同意相关变更，应当立即停止使用本网站服务；您继续使用本网站服务，即表示您接受并同意经修订的协议的约束。 
      <br> 三、注册 
      <br> 3.1 注册 
      <br> 您确认并承诺：在您完成注册程序或以其他本网站允许的方式实际使用本网站提供的服务时，您应当是具备可适用的法律规定的可签署本协议及使用本网站服务应具有的能力的自然人。您一旦点击同意注册按钮，即表示您自身已经同意该协议内容。若您不具备前述主体资格，则您应承担因此而导致的一切后果，且网站保留注销或永久冻结您账户，并向您追究责任的权利。同时，您访问和使用本网站服务，即表示您声明并保证您未被列入FATF（反洗钱金融行动特别工作组）名单，OFAC (美国财政部外国资产管制办公室）指定为“特别指定国民”名单以及任何贸易或经济制裁名单（例如联合国安全理事会制裁名单）。我们保留选择市场和司法管辖区经营业务的权利，并可自行决定在某些国家限制或拒绝提供本服务。 
      <br> 3.2 注册目的 
      <br> 您确认并承诺：您进行本网站注册并非出于违反法律法规或破坏本网站数字资产交易秩序的目的。 
      <br> 3.3.1 您同意根据本网站用户注册页面的要求提供有效电子邮箱、手机号码等信息，您可以使用您提供或确认的邮箱、手机号码或者本网站允许的其它方式作为登陆手段进入本网站。如有必要，按照不同法域相关法律法规的规定，您必须提供您的真实姓名、身份证件等法律法规及隐私条款及反洗钱条款规定的相关信息并不断更新注册资料，符合及时、详尽、准确的要求。所有原始键入的资料将引用为注册资料。您应对该等信息的真实性、完整性和准确性负责，并承担因此产生的任何直接或间接损失及不利后果。 
      <br> 3.3.2 如您所在主权国家或地区的法律法规、规则、命令等规范对手机号码有实名要求，您同意提供注册的手机号码是经过实名登记的，如您不按照规定提供，因此给您带来的任何直接或间接损失及不利后果均应由您承担。 
      <br> 四、服务 
      <br> 本网站只为您提供网络平台服务，本网站并不作为买家或卖家参与买卖数字资产行为本身。本网站暂不提供任何国家法定货币充入和提取的相关服务。 
      <br> 4.1 服务内容 
      <br> 4.1.1 您有权在本网站浏览数字资产各项产品的实时行情及交易信息、有权通过本网站提交数字资产交易指令并完成数字资产交易。 
      <br> 4.1.2 您有权在本网站查看其本网站用户账号下的信息，有权应用本网站提供的功能进行操作。 
      <br> 4.1.3 您有权按照本网站发布的活动规则参与本网站组织的网站活动。 
      <br> 4.1.4 本网站承诺为您提供的其他服务。 
      <br> 五、本网站的权利和义务 
      <br> 5.1 如您不具备本协议约定的注册资格，则本网站有权拒绝您进行注册，对已注册的，本网站有权注销您的账号，本网站保留向您或您的有权代理人追究责任的权利。同时，本网站保留其他任何情况下决定是否接受您注册的权利。 
      <br> 5.2 本网站发现您或您的关联账户使用者根据本网站自己的判断不适合高风险投资时，有权中止或终止您的账户以及所有关联账户的使用。 
      <br> 5.3 本网站通过技术检测、人工抽检等检测方式合理怀疑您提供的信息错误、不实、失效或不完整时，有权通知您更正、更新信息或中止、终止为其提供本网站服务。 
      <br> 5.4 本网站有权在发现本网站上显示的任何信息存在明显错误时，对信息予以更正。 
      <br> 5.5 本网站保留随时修改、中止或终止本网站服务的权利，本网站行使修改或中止服务的权利不需事先告知您；本网站终止本网站一项或多项服务的，终止自本网站在网站上发布终止公告之日生效。 
      <br> 5.6 本网站应当采取必要的技术手段和管理措施保障本网站的正常运行，并提供必要、可靠的交易环境和交易服务，维护数字资产交易秩序。 
      <br> 5.7 本网站通过加强技术投入、提升安全防范等措施保障您的数字资产的安全，有义务在您账户出现可以预见的安全风险时提前通知您。 
      <br> 5.8 本网站有权在您在进行相关交易或提取资金的时候收取部分手续费。 
      <br> 5.9 本网站有权随时删除本网站内各类不符合法律法规或本网站规定等的内容信息，本网站行使该等权利不需提前通知您。 
      <br> 5.10 本网站有权根据您所属主权国家或地区的法律法规、规则、命令等规范的要求，向您要求提供更多的信息或资料等，并采取合理的措施，以符合当地的规范之要求，您有义务配合；本网站有权根据您所属主权国家或地区的法律法规、规则、命令等规范的要求，暂停或永久停止对您的开放本网站及其部分或全部服务。 
      <br> 5.11 用户在完成缴纳税或通道费后发起的前三个提现指令提现金额若超过货币政策红线和货币监管机构规定的最高转账金额（1000USDT）.账号将被自动激活美国证券交易委员会（SEC）和美国税务局（IRS）的个人资产验证程序（capitalveriflcatiom）.为防止验资过程中作弊，禁止使用Coinbase账户资金完成验资手续
            `,
      3: `
      我们Coinbase（下文第11节中列出的Coinbase实体，此处称为“我们”、“我们”或“我们的”）尊重并保护探索我们的服务的用户（“用户”）和注册并访问我们的服务（“客户”）的用户（在本政策中统称为“您”和“您的”）的隐私。<br>
      本隐私政策描述了当您探索、注册或访问我们的“服务”时，我们如何收集、使用和共享个人信息，其中包括我们网站上提供的服务，包括coinbase.com、coinbase.com/exchange、coinbase.com/prime、coinbase.com/futures、coinbase.com/cloud（每个网站都是“网站”，统称为“网站”），或者当您使用coinbase移动应用程序、coinbase卡应用程序、coinbase exchange、CoinbasePrime、Dapp钱包或coinbase托管应用程序编程接口（“API”）或依赖于此类API（统称为“应用程序”）和相关服务的第三方应用程序时。<br>
      如果您居住在英国和欧洲经济区（“EEA”）以外，访问和使用我们的服务意味着您接受本隐私政策及其条款。<br>
      您必须了解我们如何使用您的信息。您应该完整阅读本页，但以下是关键亮点和一些有用的链接：<br>
      我们的目标是简化您的加密体验。如果您不希望按照本隐私政策的规定收集、使用或披露您的个人信息，或者您未满18岁，您应停止访问我们的服务。<br>
      我们收集和使用您的信息是为了提供和改善我们的服务和您的体验，保护我们平台的安全和完整性，并履行我们的法律义务。<br>
      要了解更多信息，请浏览第1节。我们收集的信息和第2节。我们如何使用您的信息。<br>
      我们与其他Coinbase公司以及值得信赖的第三方和服务提供商共享您的信息，以提供我们的服务并满足法律要求。<br>
      要了解更多信息，请浏览第3节。我们如何以及为什么共享您的信息。<br>
      我们为您提供隐私工具，以请求访问或删除我们持有的有关您的信息。您可以通过访问您的隐私权面板来使用这些工具。根据您居住的地方，您还可能拥有法律规定的其他隐私权。<br>
      如果您有任何问题，请通过我们的支持门户网站或dpo@coinbase.com.参见第9节。如何与我们联系以获取更多信息。
      `
    },
    EN: {
      1: `
      Coinbase is a US cryptocurrency exchange. The company was established in 2012. As of March 2021, Coinbase is the largest cryptocurrency exchange in the United States in terms of trading volume. On April 13, 2021, Coinbase was listed on the NASDAQ Stock Exchange, becoming the first cryptocurrency company to go public in the United States. Coinbase Pro, headquartered in the United States, provides users with a secure platform to facilitate various digital asset investments. The Coinbase Pro platform interface is simple and user-friendly, including real-time order queries, chart tools, transaction history, and a simple order process.

     `,
      2: `
      Thank you for choosing Coinbase products. 
      <br> Coinbase is a platform for users to provide blockchain-related services (hereinafter referred to as "the service" or "service"). For the convenience of expression in this agreement, the company and the website are collectively referred to as "we" or other first-person titles in this agreement. As long as the natural person or other subjects who log in to this website are users of this website, for the convenience of expressing this agreement, they are collectively referred to as "you" or other second persons. For the convenience of this agreement, we and you are collectively referred to as "the parties" in this agreement, and we or you are individually referred to as a "party". For the convenience of users, all content on this website may be provided in multiple language versions. In case of conflicts or omissions, the Chinese content shall prevail. 
      <br> important hint: 
      <br> We hereby remind you: 
      <br> 1. The digital assets themselves are not issued by any financial institution or company or this website; 
      <br> 2. The digital asset market is brand new, unconfirmed, and may not grow; 
      <br> 3. There are extremely high risks in digital asset investment, non-professional teams or institutions should invest cautiously; 
      <br> 4. It is forbidden to use this website to engage in all illegal transactions such as money laundering, smuggling, and commercial bribery. If such incidents are discovered, this website will take various available means, including but not limited to freezing accounts and notifying relevant authorities. We will not bear all the responsibilities and related expenses arising therefrom, and reserve the right to pursue responsibility from relevant persons. 
      <br> 5. It is forbidden to use this website to conduct malicious manipulation of the market, improper transactions and other unethical trading activities. If such incidents are found, this website will warn and restrict transactions for all unethical behaviors such as malicious price manipulation and malicious influence on the trading system. For preventive protection measures such as closing accounts and closing accounts, we will not bear all the responsibilities and related expenses arising therefrom, and reserve the right to pursue responsibilities against relevant persons. 
      <br> 6.The amount and profit of wealth management mining can only be withdrawn after the transaction. 
      <br> 7.When you withdraw money, the website will charge 10% commission. Users below VIP5 of this website can only withdraw 1 time per month, and users above VIP5 can withdraw 3 times per month. 
      <br> 8.The USDT deposited by each user needs to be traded in full once and the transaction volume can be completed before it can be withdraw. 
      <br> 1. General principles 
      <br> 1.1 "User Agreement" (hereinafter referred to as "this agreement" or "these terms and conditions") consists of the text, "Privacy Policy", "User Anti-Money Laundering Regulations" and various rules that have been published or may be published on this website in the future, Statements, descriptions, etc. constitute. 
      <br> 1.2 Before using the services provided by this website, you should read this agreement carefully, and if there is anything you don’t understand or if it is necessary, please consult a professional lawyer. If you do not agree to this agreement or have any questions about it, please immediately stop using the services provided by this website or log in to this website again. Once you log in to this website, use any service of this website or any other similar behavior, it means that you have understood and fully agreed to the contents of this agreement, including any modifications made by this website to this agreement at any time. 
      <br> 1.3 You can become a user of this website by filling in the relevant information in accordance with the requirements of this website and successfully registering after other relevant procedures. Clicking the "Agree" button during the registration process means that you have reached an agreement with the company in the form of an electronic signature; Or when you click on any button marked with "agree" or similar meaning during the use of this website, or actually use the services provided by this website in other ways permitted by this website, it means that you fully understand, agree and accept this agreement Without your written signature, this agreement will have no effect on your legal binding force. 
      <br> 1.4 After you become a user of this website, you will obtain a user account and corresponding password, which are kept by the user; the user shall be legally responsible for all activities and events carried out with your account. 
      <br> 1.5 Only users who register as this website can use the digital asset trading platform provided by this website to conduct transactions and enjoy other services stipulated by this website that only users can obtain. 
      <br> 1.6 By registering and using any of the services and functions provided by this website, you will be deemed to have read, understood and accepted all the terms and conditions of this agreement: 
      <br> 1.6.1 You confirm that you are over 18 years old or have the legal age to enter into a contract according to different applicable laws. Your registration on this website, sales or purchase, release of information, etc. to accept the services of this website should be Comply with the relevant laws and regulations of the sovereign country or region that has jurisdiction over you, and have sufficient ability to accept these terms, enter into transactions, and use this website for digital asset transactions. 
      <br> 1.6.2 You guarantee that the digital assets involved in the transaction are legally obtained and owned by you. 
      <br> 1.6.3 You agree that you are solely responsible for your own trading or non-trading actions and any gains or losses. 
      <br> 1.6.4 You confirm that the information provided during registration is true and accurate. 
      <br> 1.6.5 You agree to comply with the requirements of any relevant laws, for tax purposes, including paying 20% personal income tax and reporting any trading profits. 
      <br> 1.6.6 You comply with all applicable legal requirements, including but not limited to all tax laws and regulations, exchange control requirements and registration requirements. 
      <br> 1.6.7 You agree not to engage in or participate in acts or activities that damage the interests of this website or the company at any time, regardless of whether it is related to the services provided by this website. 
      <br> 1.6.8 This agreement only restricts the rights and obligations between you and us, and does not involve the legal relationship and legal disputes between users of this website, other websites and you due to digital asset transactions. 
      <br> 2. Amendments to the Agreement 
      <br> We reserve the right to amend this agreement at any time and make an announcement by means of website publicity without notifying you separately. The changed agreement will mark the time of change on the home page of this agreement, and once published on the website, it will automatically take effect immediately. You should browse and pay attention to the update time and update content of this agreement from time to time. If you do not agree with the relevant changes, you should immediately stop using the services of this website; your continued use of the services of this website means that you accept and agree to be bound by the revised agreement . 
      <br> 3. Registration 
      <br> 3.1 Registration 
      <br> You confirm and promise: when you complete the registration process or actually use the services provided by this website in other ways permitted by this website, you should have the ability to sign this agreement and use the services of this website as stipulated by applicable laws of natural persons. Once you click the Agree to Register button, it means that you have agreed to the content of the agreement. If you do not have the aforementioned subject qualifications, you shall bear all the consequences resulting therefrom, and the website reserves the right to cancel or permanently freeze your account and hold you accountable. At the same time, when you visit and use the services of this website, you represent and guarantee that you are not included in the FATF (Financial Action Task Force on Anti-Money Laundering) list, OFAC (U.S. Department of the Treasury Office of Foreign Assets Control) designated as a "specially designated national" list and any trade or economic sanctions lists (such as the United Nations Security Council Sanctions List). We reserve the right to choose the markets and jurisdictions in which we operate and may, in our sole discretion, restrict or refuse to provide the Service in certain countries. 
      <br> 3.2 Purpose of Registration 
      <br> You confirm and promise: your registration on this website is not for the purpose of violating laws and regulations or disrupting the order of digital asset transactions on this website. 
      <br> 3.3.1 You agree to provide valid email address, mobile phone number and other information in accordance with the requirements of the user registration page of this website, and you can use the email address, mobile phone number you provided or confirmed, or other methods allowed by this website as a means of logging in to this website. If necessary, in accordance with the relevant laws and regulations of different jurisdictions, you must provide your real name, ID card and other relevant information stipulated in laws and regulations, privacy clauses and anti-money laundering clauses, and constantly update the registration information to meet the timely, detailed and accurate requirements. Require. All original typed data will be cited as registered data. You shall be responsible for the authenticity, completeness and accuracy of such information, and bear any direct or indirect losses and adverse consequences arising therefrom. 
      <br> 3.3.2 If the laws, regulations, rules, orders, etc. of your sovereign country or region have real-name requirements for mobile phone numbers, you agree that the registered mobile phone number provided has been registered with real names. If you do not provide it in accordance with the regulations, you will be brought Any direct or indirect losses and adverse consequences arising therefrom shall be borne by you. 
      <br> 4. Service 
      <br> This website only provides online platform services for you, and this website does not participate in the act of buying and selling digital assets as a buyer or seller. This website does not currently provide services related to the deposit and withdrawal of any national legal currency. 
      <br> 4.1 Service content 
      <br> 4.1.1 You have the right to browse the real-time market and transaction information of various digital asset products on this website, and have the right to submit digital asset transaction instructions and complete digital asset transactions through this website. 
      <br> 4.1.2 You have the right to view the information under the user account of this website on this website, and have the right to operate using the functions provided by this website. 
      <br> 4.1.3 You have the right to participate in the website activities organized by this website in accordance with the activity rules published on this website. 
      <br> 4.1.4 Other services promised by this website to provide you. 
      <br> 5. Rights and obligations of this website 
      <br> 5.1 If you do not have the registration qualifications stipulated in this agreement, this website has the right to refuse your registration, and if you have registered, this website has the right to cancel your account, and this website reserves the right to hold you or your authorized agent accountable s right. At the same time, this website reserves the right to decide whether to accept your registration under any other circumstances. 
      <br> 5.2 When this website finds that you or your associated account users are not suitable for high-risk investment according to this website's own judgment, it has the right to suspend or terminate your account and the use of all associated accounts. 
      <br> 5.3 When this website reasonably suspects that the information you provide is wrong, untrue, invalid or incomplete through technical testing, manual sampling and other testing methods, it has the right to notify you to correct and update the information or to suspend or terminate the service provided by this website. 
      <br> 5.4 This website has the right to correct the information when any information displayed on this website is found to be obviously wrong. 
      <br> 5.5 This website reserves the right to modify, suspend or terminate the services of this website at any time, and this website does not need to inform you in advance to exercise the right to modify or suspend services; It will take effect on the date of the announcement of termination. 
      <br> 5.6 This website shall adopt necessary technical means and management measures to ensure the normal operation of this website, provide necessary and reliable trading environment and trading services, and maintain the order of digital asset transactions. 
      <br> 5.7 This website protects the security of your digital assets by strengthening technical investment, improving security precautions and other measures, and is obliged to notify you in advance when there are foreseeable security risks in your account. 
      <br> 5.8 This website has the right to charge part of the service fee when you conduct related transactions or withdraw funds. 
      <br> 5.9 This website has the right to delete all kinds of content information on this website that does not comply with laws and regulations or the regulations of this website at any time, and this website does not need to notify you in advance to exercise these rights. 
      <br> 5.10 This website has the right to request you to provide more information or materials in accordance with the laws, regulations, rules, orders and other normative requirements of your sovereign country or region, and to take reasonable measures to meet the requirements of local norms, You are obliged to cooperate; this website has the right to suspend or permanently stop the opening of this website and some or all of its services to you in accordance with the laws, regulations, rules, orders and other specifications of your sovereign country or region. 
      <br> 5.11 If the withdrawal amount of the first three withdrawal instructions initiated by the user after paying taxes or channel fees exceeds the monetary policy red line and the maximum transfer amount (1000USDT) stipulated by the monetary regulatory agency, the account will be automatically activated by the US Securities and Exchange Commission (SEC) and the US Securities and Exchange Commission (SEC) and the US The personal asset verification procedure (capital verification) of the Inland Revenue Department (IRS). In order to prevent cheating during the capital verification process, it is forbidden to use the funds in the Coinbase account to complete the capital verification procedures.
            `,
      3: `
      We at Coinbase (the Coinbase entities listed in Section 11 below, referred to here as “we”, “us” or “our”) respect and protect the privacy of those who explore our Services (“Users”) and Users who sign up for and access our Services (“Customers”) (together referred throughout this policy as “you” and “your”).<br />
      This Privacy Policy describes how we collect, use, and share personal information when you explore, sign up for or access our “Services”, which include the services offered on our websites, including coinbase.com, coinbase.com/exchange, coinbase.com/prime, coinbase.com/futures, coinbase.com/cloud (each a “Site” and collectively the "Sites") or when you use the Coinbase mobile app, the Coinbase Card App, Coinbase Exchange, Coinbase Prime, Dapp Wallet, or Coinbase Custody application programming interface (“API”) or third party applications relying on such APIs (together, our “Apps”) and related services. <br />
      If you reside outside of the UK and the European Economic Area (the “EEA”), accessing and using our Services means that you accept this Privacy Policy and its terms. <br />
      It is important that you understand how we use your information. You should read this page in full, but below are the key highlights and some helpful links:<br />
      Our goal is to simplify your crypto experience. If you do not wish for your personal information to be collected, used, or disclosed as described in this Privacy Policy, or you are under 18 years of age, you should stop accessing our Services.<br />
      We collect and use your information in order to provide and improve our Services and your experience, protect the security and integrity of our platform, and meet our legal obligations.<br />
      To learn more, explore Section 1. What Information We Collect and Section 2. How We Use Your Information.<br />
      We share your information with other Coinbase companies, as well as trusted third parties and service providers, in order to offer our Services and fulfill legal requirements.<br />
      To learn more, explore Section 3. How And Why We Share Your Information.<br />
      We offer privacy tools for you to request access to or deletion of information we hold about you. You can use these tools by visiting your Privacy Rights Dashboard. Depending on where you live, you may also have other privacy rights under law.<br />
      If you have any questions, please contact us on our Support Portal or at dpo@coinbase.com. See Section 9. How to Contact Us With Questions for more information.
      `
    },

  };


  const Html = `
    <html>
      <head>
      <meta charset='utf-8' /><meta name="viewport" content="width=device-width,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"/>
      </head>
      <body>
        <div style="width: 100%; height: 100%; display: flex;  justify-content: center;">

          ${articleContent[lang][title_id]}
        </div>
      </body>
    </html>
  `;

  return (
    <View style={{ flex: 1 }}>

      {Platform.OS != 'web' &&
        <WebView
          style={{ flex: 1 }}
          source={{ html: Html }}
        />
      }

      {Platform.OS == 'web' &&
        <iframe srcDoc={Html} width="100%" height="100%" />
      }

    </View>
  );
};

export default ArticleDetail;