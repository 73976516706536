import { useState, useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View, Image, TouchableOpacity, Text, TextInput, ScrollView, Platform, Modal } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { commonStyle } from '../components/style';
import Button from '../components/Button';
import ImageViewer from '../components/ImageViewer';
import { useTranslation } from 'react-i18next';
import { showAlert } from '../components/showAlert';
import { upload, securitycenterinit, certification, getWebsiteInfo } from '../api/apis';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { Feather, AntDesign } from '@expo/vector-icons';

const PlaceholderImageFront = require('../../assets/imgs/common/imgload.png');
const PlaceholderImageBack = require('../../assets/imgs/common/imgload.png');

export default function App() {

    const { t, i18n } = useTranslation();
    const navigation = useNavigation();
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageBack, setSelectedImageBack] = useState(null);
    const [fullName, setFullName] = useState('');
    const [idcard, setIdcard] = useState(null);
    const [user_verification_level, setUser_verification_level] = useState('');
    const [staticDomain, setStaticDomain] = useState('');
    const [user_IDphotoone, setUser_IDphotoone] = useState('');
    const [user_IDphototwo, setUser_IDphototwo] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);

    const [modalVisible, setModalVisible] = useState(false);

    const [email, setEmail] = useState(''); // 邮箱
    const [nationality, setNationality] = useState(''); //国籍
    const [documentType, setDocumentType] = useState(t('identityCard')); //证件类型
    const [documentTypeSelect, setDocumentTypeSelect] = useState([
        { id: 1, name: t('identityCard') },
        { id: 2, name: t('passport') },
        { id: 3, name: t("drivingLicense") },
        { id: 4, name: t('visa') },
    ]);

    const [documentNumber, setDocumentNumber] = useState(''); //证件号
    const [phone, setPhone] = useState(''); //手机号

    useEffect(() => {
        // alert(i18n.language)


        init()
    }, []);


    const init = async () => {

        const websiteInfo = await getWebsiteInfo();
        if (websiteInfo.status == '1') {
            setStaticDomain(websiteInfo.Data.static)
        }


        // 是否上传，审核过
        const formdata = {
            // user_holdingIDcard: selectedImage,
            type: "show"
        }
        certification(formdata).then(response => {
            // console.log(response)
            if (response.status == 1) {
                const itemData = response.Data
                // itemData.user_creditscore //信誉分
                // itemData.user_IDnumber //身份证号
                // itemData.user_IDphotoone //身份证正面
                // itemData.user_IDphototwo //身份证反面
                // itemData.user_holdingIDcard // 手持身份证
                // itemData.user_verification_level // 认证等级 user_verification_level 这是3位数手持身份证是百位1是已经上传还没审核通过2是审核没有通过 3是审核通过  十位 反面认证1是已经上传还没审核通过2是审核没有通过 3是审核通过      个位数是正面认证1是已经上传还没审核通过2是审核没有通过 3是审核通过 
                // setUser_verification_level('000')
                setUser_verification_level(itemData.user_verification_level)
                setUser_IDphotoone(itemData.user_IDphotoone)
                setUser_IDphototwo(itemData.user_IDphototwo)
            }
        }).catch(error => {
            console.log(error)
            // showAlert(t('fail'));
            // console.error(error);
        });

    }

    const getImageTypeFromBase64 = (base64Data) => {
        const matches = base64Data.match(/^data:(.+);base64,/);
        if (matches && matches.length > 1) {
            return matches[1];
        }
        return null;
    };

    const pickImageAsync = async (side) => {
        const allowedTypes = ['jpeg', 'jpg', 'png'];
        let result = await ImagePicker.launchImageLibraryAsync({
            allowsEditing: true,
            quality: 1,
        });

        if (!result.canceled) {
            const uri = result.assets[0].uri;
            // 获取文件扩展名
            let extension = '';

            if (Platform.OS != 'web') {
                extension = uri.split('.').pop().toLowerCase();
            } else {
                extension = getImageTypeFromBase64(uri);
                extension = extension.replace('image/', '')
            }

            if (!allowedTypes.includes(extension)) {
                showAlert(t('jpgpng'));
                return;
            }

            if (side == 'front') {
                setSelectedImage(result.assets[0].uri);
            } else {
                setSelectedImageBack(result.assets[0].uri);
            }
        } else {
            // alert("You did not select any image.");
        }
    };


    function validateEmail(email) {
        // 邮箱格式的正则表达式
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // 使用正则表达式进行验证
        return emailRegex.test(email);
    }

    const handleUpload = async () => {


        if (isSubmit) {
            return
        }

        if(nationality == ''){
            showAlert(t('nationality'));
            return;
        }

        if(documentNumber == ''){
            showAlert(t('documentNumber'));
            return;
        }

        // if(phone == ''){
        //     showAlert(t('phone'));
        //     return;
        // }



        if(fullName == ''){
            showAlert(t('fullName'));
            return;
        }

        // if (email == '' || !validateEmail(email)) {
        //     showAlert(t('emailError'));
        //     return;
        // }

        // if (!selectedImage) {
        //     showAlert(t('pleaseSelectFrontIDCard'));
        //     return;
        // }

        // if (!selectedImageBack) {
        //     showAlert(t('pleaseSelectBackIDCard'))
        //     return;
        // }

        const formdata = {
            user_IDphotoone: selectedImage,
            user_IDphototwo: selectedImageBack,
            // email: email,
            nationality:nationality,
            documentType:documentType,
            documentNumber:documentNumber,
            // phone:phone,
            fullName:fullName,
            type: "up"
        }

        setIsSubmit(true)

        certification(formdata).then(response => {
            setIsSubmit(false)
            if (response.status == 1) {

                showAlert(t('success'), () => {
                    navigation.goBack()
                });

            } else {
                showAlert(t('fail'));
            }
        }).catch(error => {
            setIsSubmit(false)
            showAlert(t('fail'));
            // console.error(error);
        });

    };

    const handleFullNameChanged = (text) => {
        setFullName(text);
    };

    const handleIdcardChanged = (text) => {
        setIdcard(text);
    };


    const handleEmailChanged = text => {
        setEmail(text);
    };


    const closeModal = () => {
        setModalVisible(false);
    };

    const handleSelectBank = (item) => {
        setDocumentType(item.name)
        setModalVisible(false);
    }

    
    const handleSelectNationality = (item) => {
     
        setNationality(item.code + '-' +item.city)
        closeModal();
    }


    return (
        <View style={styles.container}>

            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={{ display: user_verification_level != '' ? 'block' : 'none' }}
            >
                {/* <View >
                <TextInput
                    style={styles.input}
                    placeholder={t('fullName')}
                    onChangeText={handleFullNameChanged}
                    value={fullName}
                    underlineColorAndroid="transparent"
                    placeholderTextColor={commonStyle.green} />
            </View>
            <View >
                <TextInput
                    style={styles.input}
                    placeholder={t('identityCard')}
                    onChangeText={handleIdcardChanged}
                    value={idcard}
                    underlineColorAndroid="transparent"
                    placeholderTextColor={commonStyle.green} />
            </View> */}


                {/* <View style={styles.imageContainer}>
                    {selectedImage && <ImageViewer placeholderImageSource={PlaceholderImageFront} selectedImage={selectedImage} />}
                    <View style={{ height: 5 }}></View>
                    {selectedImageBack && <ImageViewer placeholderImageSource={PlaceholderImageBack} selectedImage={selectedImageBack} />}
                </View>

                <View style={{ height: 5 }}></View> */}

                {(user_verification_level != '33' && user_verification_level != '333' && user_verification_level != '11' && user_verification_level != '10' && user_verification_level != '01') &&
                    <View style={styles.footerContainer}>
                        {/* <Button theme="primary" label={t('pleaseSelectFrontIDCard')} onPress={() => pickImageAsync('front')} />
                        <View style={{ height: 5 }}></View>
                        <Button theme="primary" label={t('pleaseSelectBackIDCard')} onPress={() => pickImageAsync('back')} /> */}

              
                        <View style={{ marginTop: 10 }}>
                            <TextInput
                                style={styles.input}
                                placeholder={t('nationality') + nationality}
                                onChangeText={value => setNationality(value)}
                                value={nationality}
                             
                                underlineColorAndroid="transparent"
                                placeholderTextColor={commonStyle.green} />
                          
                        </View>
                  
                        <TouchableOpacity onPress={() => {
                            setModalVisible(true)
                        }}>
                            <View style={{ marginTop: 10 }} >
                                <TextInput
                                    style={[styles.input, { flex: 1 }]}
                                    placeholder={t('documentType')}
                                    value={documentType}
                                    editable={false}
                                    underlineColorAndroid="transparent"
                                    placeholderTextColor={commonStyle.green} />
                                <Feather name="chevron-down" size={20} style={{ color: commonStyle.green, position: 'absolute', 'right': 10, 'top': 10 }} />
                            </View>
                        </TouchableOpacity>

                        <View style={{ marginTop: 10 }}>
                            <TextInput
                                style={styles.input}
                                placeholder={t('documentNumber')}
                                onChangeText={value => setDocumentNumber(value)}
                                value={documentNumber}
                                underlineColorAndroid="transparent"
                                placeholderTextColor={commonStyle.green} />
                        </View>
                        <View style={{ marginTop: 10 }}>
                            <TextInput
                                style={styles.input}
                                placeholder={t('fullName')}
                                onChangeText={handleFullNameChanged}
                                value={fullName}
                                underlineColorAndroid="transparent"
                                placeholderTextColor={commonStyle.green} />
                        </View>

                        <View style={{ marginTop: 10 }}>
                            <TextInput
                                style={styles.input}
                                placeholder={t('phone')}
                                onChangeText={value => setPhone(value)}
                                value={phone}
                                underlineColorAndroid="transparent"
                                placeholderTextColor={commonStyle.green} />
                        </View>
                        {/* <View style={{ marginTop: 10 }}>
                            <TextInput
                                style={styles.input}
                                placeholder={t('email')}
                                onChangeText={handleEmailChanged}
                                value={email}
                                underlineColorAndroid="transparent"
                                placeholderTextColor={commonStyle.green} />
                        </View> */}
                    </View>}


                {/* <View style={styles.footerContainer}>
                    <Button theme="primary" label={t('pleaseSelectFrontIDCard')} onPress={() => pickImageAsync('front')} />
                    <View style={{ height: 5 }}></View>
                    <Button theme="primary" label={t('pleaseSelectBackIDCard')} onPress={() => pickImageAsync('back')} />
                </View>
                <TouchableOpacity style={styles.button} onPress={handleUpload}>
                    <Text style={styles.buttonText}>{t('confirm')}</Text>
                </TouchableOpacity> */}


                {(user_verification_level == '11' || user_verification_level == '10' || user_verification_level == '01' || user_verification_level == '33') && staticDomain &&
                    <View>
                        {user_IDphotoone && <Image style={[{ marginBottom: 10, height: 170, borderRadius: 5 }]} source={{ uri: staticDomain + '/' + user_IDphotoone }} />}
                        {user_IDphototwo && <Image style={[{ height: 170, borderRadius: 5 }]} source={{ uri: staticDomain + '/' + user_IDphototwo }} />}
                    </View>}


                <View style={{ height: 5 }}></View>

                <View style={{ alignItems: 'center' }}>

                    {user_verification_level != '11' && user_verification_level != '10' && user_verification_level != '01' && user_verification_level != '333' && user_verification_level != '33' &&
                        <TouchableOpacity style={styles.button} onPress={handleUpload}>
                            <Text style={styles.buttonText}>{t('confirm')}</Text>
                        </TouchableOpacity>}

                    {(user_verification_level == '11' || user_verification_level == '10' || user_verification_level == '01') &&
                        <TouchableOpacity style={styles.button}>
                            <Text style={styles.buttonText}>{t('audit')}</Text>
                        </TouchableOpacity>}

                    {(user_verification_level == '33' || user_verification_level == '333') &&
                        <TouchableOpacity style={styles.button}>
                            <Text style={styles.buttonText}>{t('end')}</Text>
                        </TouchableOpacity>}

                </View>

            </ScrollView >
            <StatusBar style="auto" />


            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <AntDesign name="down" onPress={() => { closeModal() }}
                                size={24} color="#707070" style={styles.modelDown}
                            />
                        </View>

                        <ScrollView
                            showsVerticalScrollIndicator={false}
                            showsHorizontalScrollIndicator={false}

                        >
                            <View>

                                {documentTypeSelect.map((item, index) => (
                                    <TouchableOpacity key={index} onPress={() => handleSelectBank(item)}>
                                        <Text style={[styles.modalText]}>{item.name}</Text>
                                    </TouchableOpacity>
                                ))}

                            </View>
                        </ScrollView>
                    </View>
                </View>
            </Modal>



        

            {/* nationalitySelect[i18n.language] */}



        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,  // 主轴上尺寸比例
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center', // 交叉轴对齐方式
        justifyContent: 'center',//主轴对齐方式
    },
    imageContainer: {
        // flex: 1,
        // paddingTop: 28
    },
    footerContainer: {
        // flex: 1 / 3,
        // alignItems: 'center',
    },
    button: {
        // backgroundColor: '#2C3445',
        backgroundColor: commonStyle.green,
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        marginTop: 20,
        borderRadius: 5,
        width: 280,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        fontSize: 16
    },
    input: {
        // placeholderTextColor:'#707070',
        // color: '#707070',
        marginBottom: 15,
        // paddingLeft:5,
        height: 40,
        borderWidth: 1,
        borderColor: commonStyle.green,
        borderRadius: 5,
        padding: 5,
        color: commonStyle.green,
        backgroundColor: '#222430'
        // borderBottomColor: 'transparent'
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        maxWidth: 500,
        width: '100%',
        marginHorizontal: 'auto'
        // backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: '#1B1D29',
        // padding: 20,
        borderRadius: 10,
        width: '100%',
        height: '100%'
    },
    closeButton: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#fff',
        marginTop: 16,
        textAlign: 'center',
    },
    modelDown: {
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 'auto',
        marginBottom: 15,
        marginTop: 20
    },
    modalText: {
        color: '#8C99B3',
        textAlign: 'center',
        height: 40,
        fontSize: 16,
        lineHeight: 35
    },
    modalTextSelected: {
        backgroundColor: '#0F1017',
        opacity: 0.45
    }
});
