import React, { useState, useEffect } from 'react';
import { View, Text, Image, TouchableOpacity, FlatList, ActivityIndicator, StyleSheet, RefreshControl,Platform } from 'react-native';
import ActiveTabItem from '../components/activeTabItem'
import { commonStyle } from '../components/style'
import { useTranslation } from 'react-i18next';
import { FontAwesome } from '@expo/vector-icons';
import { getTraderecord } from '../api/apis';
import { transactionType } from '../components/util'

const App = () => {

    const { t } = useTranslation();

    const [data, setData] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [page, setPage] = useState(0);
    const [emptyShow, setEmptyShow] = useState(false);

    useEffect(() => {
        console.log('page:billingRecord')
        // 初始化数据
        handleRefresh()
    }, []);

    // 刷新
    const handleRefresh = () => {
        setRefreshing(true)
        setPage(0)
        if (Platform.OS == 'web') {
            setLoadingMore(true);
        }
        setTimeout(() => {
            record()
        }, 1000);
    };

    // 加载更多数据
    const handleLoadMore = () => {
        setLoadingMore(true);
        // setPage(parseInt(page) + 1)
        setTimeout(() => {
            record()
        }, 1000);
    };


    //获取记录
    const record = async () => {

        const nextPage = page + 1;
        setPage(nextPage)

        await getTraderecord(nextPage, '').then(response => {
            setRefreshing(false);
            setLoadingMore(false);
 
            // console.log(response.Data1)
            if (response.status == 1) {
                const updateData = response.Data1.item.map(data => {
                    const typeName = data.typeName

                    // data.typeName = '35'
                    // const typeName = t(tranytype(data.typeName))
                    const addDate = new Date(parseInt(data.add_date)).toLocaleString();

                  
                    // data.outMoney = Math.abs(data.outMoney);
                    // data.inMoney = Math.abs(data.inMoney);
                    
                    let inMoney = data.inMoney == '' ? '' : (data.inMoney / 100).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    });

                    let outMoney = data.outMoney == '' ? '' : (data.outMoney / 100).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    });

                    // inMoney = Math.abs(inMoney);
                    // outMoney = Math.abs(outMoney);

                    // console.log(inMoney + '='+outMoney + '='+ Math.abs(outMoney).toFixed(2))

                
                    return { ...data, typeName: t(transactionType(data.typeName)), add_date: addDate, inMoney: inMoney, outMoney: outMoney };
                    // return { ...data, typeName: "PNL", add_date: addDate, inMoney: inMoney, outMoney: outMoney };
                });
                const dataList = data.concat(updateData)
                if (nextPage > 1) {
                    setData(dataList)
                } else {
                    setData(updateData)
                    if (dataList.length == 0) {
                        setEmptyShow(true)
                    }
                }
            }
        }).catch(error => {
            setRefreshing(false);
            setLoadingMore(false);
            console.error(error);
        });
    };

    const renderItem = ({ item }) => (
        <View style={styles.itemContainer}>
            {/* <View style={styles.itemRowContainer}>
                <View><Text style={styles.itemTextGreen}>{item.typeName}</Text></View>
            </View> */}
            <View style={styles.itemRowContainer}>
                <View><Text style={styles.itemTextGray}>{item.add_date}</Text></View>
                <View>
                    <TouchableOpacity>
                        {item.outMoney != '' && item.outMoney != '0.00' &&
                            <Text style={styles.itemTextRed}>
                              {item.outMoney[0] == "-" ? '' :'-' }{item.outMoney}
                            </Text>
                        }
                        {item.inMoney != '' && item.inMoney != '0.00' &&
                            <Text style={styles.itemTextGreen}>
                                {item.inMoney[0] == "+" ? '' :'+' }{item.inMoney}
                            </Text>
                        }
                        {item.outMoney == '' && item.inMoney == '0.00' &&
                            <Text style={styles.itemTextGreen}>
                                {item.inMoney[0] == "+" ? '' :'+' }{item.inMoney}
                            </Text>
                        }

                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );

    return (
        <View style={styles.container}>
            {emptyShow && <View style={{ justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                <Text style={{ color: '#fff' }}>{t('noData')}</Text>
            </View>}
            {/* <Image style={styles.image} source={require('../../assets/imgs/common/empty.png')} /> */}

            <FlatList
                data={data}
                renderItem={renderItem}
                keyExtractor={(item, index) => index}
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}
                onEndReached={!loadingMore && handleLoadMore}
                onEndReachedThreshold={0.1}
                ListFooterComponent={loadingMore && <ActivityIndicator color={commonStyle.green} />}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor
    },
    tabContainer: {
        flexDirection: 'row',
        paddingVertical: 10,
        backgroundColor: '#1B1D29',
    },
    tabItem: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: 10,
    },
    tabText: {
        color: commonStyle.textGreenColor,
    },
    itemTextGreen: {
        color: commonStyle.textGreenColor,
    },
    itemTextRed: {
        color: commonStyle.textRedColor,
    },
    itemTextGray: {
        color: commonStyle.textGrayColor
    },
    itemContainer: {
        flexDirection: "column",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        backgroundColor: '#1B1D29',
        marginTop: 5,
    },
    itemRowContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 4
    },
    activeTabItem: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {

    }

});

export default App;